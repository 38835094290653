<template>
	<div
		v-if="events.length"
		class="github wrap"
	>
		<h3>Last activities</h3>
		<ul>
			<li
				v-for="({created_at, id, payload, repo, type}, index) in visibleEvents"
				:key="id"
				:style="{animationDelay: `${index * 0.1}s`}"
				data-animation="fadeUp"
			>
				<span v-if="type === 'CreateEvent'">
					<template v-if="payload.ref">
						Released {{ payload.ref_type }}
						<strong v-if="payload.ref">{{ payload.ref }}</strong>
						of
					</template>
					<template v-else>
						Created {{ payload.ref_type }}
					</template>
				</span>
				<span v-if="type === 'PullRequestEvent'">
					Pull request {{ payload.action }}
					<a
						class="branch"
						:href="payload.pull_request.html_url"
						rel="noopener noreferrer"
						target="_blank"
						:title="`More about ${payload.pull_request.title}`"
					>
						{{ payload.pull_request.title }}
					</a>
					of
				</span> <a
					:href="`http://github.com/${repo.name}`"
					rel="noopener noreferrer"
					target="_blank"
					:title="`More about ${repo.name}`"
				>
					{{ repo.name }}
				</a>
				<time :datetime="created_at">
					{{ date(created_at) }}
				</time>
			</li>
		</ul>
		<button
			v-if="events.length > limit"
			type="button"
			@click="limit = events.length"
		>
			<span>Show more activity</span>
			<IconChevron />
		</button>
	</div>
</template>

<script>
import IconChevron from '@/assets/icons/chevron.svg?inline';
import Time from '@/utils/time';

export default {
	components: {
		IconChevron
	},
	data() {
		return {
			limit: 5,
			events: []
		};
	},
	computed: {
		visibleEvents() {
			return this.events.slice(0, this.limit);
		}
	},
	mounted() {
		fetch('https://api.github.com/users/Schascha/events?per_page=100&type=CreateEvent')
			.then(response => response.json())
			.then(data => {
				data.forEach(el => {
					const
						{org, payload, repo, type} = el,
						{events} = this
					;

					// Ignore org events
					if (org || events.length === 30) {
						return;
					}

					// CreateEvent
					if (type === 'CreateEvent') {
						// Ignore duplicates
						if (events.findIndex(el => el.repo.name === repo.name && el.payload.ref === payload.ref) === -1) {
							events.push(el);
						}
					}

					// PullRequestEvent
					if (type === 'PullRequestEvent') {
						events.push(el);
					}
				});
			});
	},
	methods: {
		date(d) {
			return new Time().ago(d);
		}
	}
};
</script>

<style lang="scss" scoped>
	ul {
		margin: 0;
		padding: 0;
		font-size: 1rem;
		list-style: none;
		text-align: left;
	}

	li {
		margin: 0 0 0.5rem;
	}

	a {
		font-weight: 600;
	}

	button {
		--icon-size: 1rem;
		color: var(--color-brand);
		font-size: rem(12);
		font-weight: 600;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	time {
		display: block;
		font-size: rem(12);
	}

	.branch {
		padding: rem(2) rem(6);
		background-color: var(--color-branch);
		border-radius: rem(6);
		font-size: rem(12);
	}
</style>
